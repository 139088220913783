import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Pricing.css";

const Pricing = () => {
  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/organizer");
  }, [navigate]);

  const onGroupLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="pricing1">
      <div className="enterprise">
        <div className="enterprise-child" />
        <div className="enterprise-item" />
        <img className="enterprise-inner" alt="" src="/vector-1.svg" />
        <img className="group-icon" alt="" src="/group-10@2x.png" />
        <b className="b">$499</b>
        <div className="professional">Enterprise</div>
        <div className="organizer-page-creation-parent">
          <div className="organizer-page-creation">Organizer Page creation</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="premium-event-listing-parent">
          <div className="organizer-page-creation">{`Premium Event Listing `}</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="full-custom-registration-forms-parent">
          <div className="organizer-page-creation">
            Full Custom Registration forms
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="in-app-payments-1-cutticket-parent">
          <div className="organizer-page-creation">
            In App Payments (1% cut/ticket)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="event-feedback-forms-parent">
          <div className="organizer-page-creation">Event feedback forms</div>
          <img className="group-item" alt="" src="/group-3.svg" />
          <img className="group-child4" alt="" src="/group-3.svg" />
        </div>
        <div className="qr-check-ins-unlimited-parent">
          <div className="organizer-page-creation">
            QR Check-Ins (Unlimited)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="advance-event-analytics-wrapper">
          <div className="advance-event-analytics">Advance Event analytics</div>
        </div>
        <div className="virtual-events-unlimited-parent">
          <div className="organizer-page-creation">
            Virtual Events (Unlimited)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="premium-designs-templates-parent">
          <div className="organizer-page-creation">
            Premium Designs templates
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="email-support-with-24-hr-respo-parent">
          <div className="organizer-page-creation">
            Email Support with 24-hr response time
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="all-discussion-boards-parent">
          <div className="organizer-page-creation">All Discussion boards</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="embed-social-media-page-widget-parent">
          <div className="organizer-page-creation">
            Embed Social media page Widgets
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="leaderboard-ranking-parent">
          <div className="organizer-page-creation">Leaderboard Ranking</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="access-to-organizers-virtual-c-parent">
          <div className="organizer-page-creation">
            Access to Organizers virtual Community
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="ads-and-sponserships-parent">
          <div className="organizer-page-creation">Ads and Sponserships</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="rectangle-parent" onClick={onGroupContainerClick}>
          <div className="rectangle-div" />
          <div className="join-waitlist">Join Waitlist</div>
        </div>
        <div className="month">/month</div>
      </div>
      <div className="pro">
        <div className="enterprise-child" />
        <div className="pro-item" />
        <img className="enterprise-inner" alt="" src="/vector-1.svg" />
        <img className="group-icon" alt="" src="/group-10@2x.png" />
        <b className="b">$199</b>
        <div className="professional">Professional</div>
        <div className="organizer-page-creation-group">
          <div className="organizer-page-creation">Organizer Page creation</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="priority-event-listing-parent">
          <div className="organizer-page-creation">{`Priority Event Listing `}</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="advanced-registration-forms-parent">
          <div className="organizer-page-creation">
            Advanced Registration forms
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="in-app-payments-3-cutticket-parent">
          <div className="organizer-page-creation">
            In App Payments (3% cut/ticket)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="event-feedback-forms-group">
          <div className="organizer-page-creation">Event feedback forms</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="qr-check-ins-1000-parent">
          <div className="organizer-page-creation">QR Check-Ins (1000)</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <img className="pro-child2" alt="" src="/group-3.svg" />
        <div className="intermediate-event-analytics-wrapper">
          <div className="advance-event-analytics">
            Intermediate Event analytics
          </div>
        </div>
        <div className="virtual-events-8-eventsmonth-parent">
          <div className="organizer-page-creation">
            Virtual Events (8 Events/month , 60mins)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="custom-designs-templates-parent">
          <div className="organizer-page-creation">
            Custom Designs templates
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="email-support-with-48-hr-respo-parent">
          <div className="organizer-page-creation">
            Email Support with 48-hr response time
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="discussion-boards-speaker-a-parent">
          <div className="organizer-page-creation">
            Discussion boards (Speaker + attendees)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="embed-social-media-page-widget-group">
          <div className="organizer-page-creation">
            Embed Social media page Widgets
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="ads-and-sponserships-group">
          <div className="organizer-page-creation">Ads and Sponserships</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="rectangle-group" onClick={onGroupContainerClick}>
          <div className="rectangle-div" />
          <div className="join-waitlist">Join Waitlist</div>
        </div>
        <div className="month1">/month</div>
      </div>
      <div className="starter">
        <div className="enterprise-child" />
        <div className="starter-item" />
        <img className="enterprise-inner" alt="" src="/vector-1.svg" />
        <img className="group-icon" alt="" src="/group-10@2x.png" />
        <b className="b">$49</b>
        <div className="professional">Starter</div>
        <div className="organizer-page-creation-container">
          <div className="organizer-page-creation">Organizer Page creation</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="event-listing-parent">
          <div className="organizer-page-creation">{`Event Listing `}</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="enhanced-registration-forms-parent">
          <div className="organizer-page-creation">
            Enhanced Registration forms
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="in-app-payments-5-cutticket-parent">
          <div className="organizer-page-creation">
            In App Payments (5% cut/ticket)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="event-feedback-forms-container">
          <div className="organizer-page-creation">Event feedback forms</div>
          <img className="group-item" alt="" src="/group-3.svg" />
          <img className="group-child4" alt="" src="/group-3.svg" />
        </div>
        <div className="qr-check-ins-500-parent">
          <div className="organizer-page-creation">QR Check-Ins (500)</div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="basic-event-analytics-wrapper">
          <div className="advance-event-analytics">Basic Event analytics</div>
        </div>
        <div className="virtual-events-5-eventsmonth-parent">
          <div className="organizer-page-creation">
            Virtual Events (5 Events/month , 60mins)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="limited-custom-designs-templat-parent">
          <div className="organizer-page-creation">
            Limited Custom Designs templates
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="email-support-with-72-hr-respo-parent">
          <div className="organizer-page-creation">
            Email Support with 72-hr response time
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="virtual-events-5-eventsmonth-group">
          <div className="organizer-page-creation">
            Virtual Events (5 Events/month , 60mins)
          </div>
          <img className="group-item" alt="" src="/group-3.svg" />
        </div>
        <div className="rectangle-container" onClick={onGroupContainerClick}>
          <div className="group-child38" />
          <div className="join-waitlist">Join Waitlist</div>
        </div>
        <div className="month2">/month</div>
      </div>
      <div className="free">
        <div className="enterprise-child" />
        <div className="free-item" />
        <img className="enterprise-inner" alt="" src="/vector-1.svg" />
        <img className="group-icon" alt="" src="/group-10@2x.png" />
        <b className="b">$0</b>
        <div className="professional">Free</div>
        <div className="basic-page-creation-parent">
          <div className="organizer-page-creation">Basic page creation</div>
          <img className="group-child39" alt="" src="/group-3.svg" />
        </div>
        <div className="event-posting-not-listing-parent">
          <div className="organizer-page-creation">
            Event Posting (not listing)
          </div>
          <img className="group-child39" alt="" src="/group-3.svg" />
        </div>
        <div className="basic-registration-forms-parent">
          <div className="organizer-page-creation">
            Basic Registration forms
          </div>
          <img className="group-child39" alt="" src="/group-3.svg" />
        </div>
        <div className="in-app-payments-8-cutticket-parent">
          <div className="organizer-page-creation">
            In-App Payments (8% cut/Ticket)
          </div>
          <img className="group-child39" alt="" src="/group-3.svg" />
        </div>
        <div className="basic-post-event-feedback-form-parent">
          <div className="organizer-page-creation">
            Basic Post Event Feedback form
          </div>
          <img className="group-child39" alt="" src="/group-3.svg" />
        </div>
        <div className="basic-insights-parent">
          <div className="organizer-page-creation">{`Basic Insights `}</div>
          <img className="group-child39" alt="" src="/group-3.svg" />
        </div>
        <div className="group-div" onClick={onGroupContainerClick}>
          <div className="rectangle-div" />
          <div className="join-waitlist3">Join Waitlist</div>
        </div>
        <div className="month2">/month</div>
      </div>
      <Link className="synclyfy-v1-1-parent" to="/" onClick={onGroupLinkClick}>
        <img className="synclyfy-v1-1-icon" alt="" src="/synclyfy-v1-1.svg" />
        <img className="synclyfyv2-1-icon" alt="" src="/synclyfyv2-1@2x.png" />
      </Link>
      <div className="note-parent">
        <div className="note">Note:</div>
        <div className="theese-plans-are">{`Theese plans are only for organizers `}</div>
      </div>
    </div>
  );
};

export default Pricing;
