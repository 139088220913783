import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Email from "../components/Email";
import "./Organizer.css";

const Organizer = () => {
  const navigate = useNavigate();

  const onGroupLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onFrameLinkClick = useCallback(() => {
    navigate("/org-success1");
  }, [navigate]);

  return (
    <div className="organizer1">
      <img className="organizer-child" alt="" src="/rectangle-6702.svg" />
      <div className="nav-bar6">
        <Link className="frame-a" to="/pricing">
          <div className="pricing5">Pricing</div>
        </Link>
      </div>
      <Link className="synclyfy-v1-1-parent2" to="/" onClick={onGroupLinkClick}>
        <img className="synclyfy-v1-1-icon5" alt="" src="/synclyfy-v1-1.svg" />
        <img className="synclyfyv2-1-icon5" alt="" src="/synclyfyv2-1@2x.png" />
      </Link>
      <div className="beta-access-is">
        Beta access is limited—join Synclyfy now!
      </div>
      <div className="rectangle-parent1">
        <div className="group-child46" />
        <div className="name-parent">
          <div className="name2">
            <input className="name-item" type="text" />
            <i className="pricing-plan">Pricing plan</i>
          </div>
          <input className="name3" placeholder="Full name" type="text" />
          <div className="email2">
            <Email />
            <a className="join-container" onClick={onFrameLinkClick}>
              <div className="join1">
                <div className="join-item" />
                <input
                  className="join2"
                  value="Join"
                  placeholder="Email"
                  type="email"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizer;
