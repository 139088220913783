import React from 'react';
import PropTypes from "prop-types";
import "./ChatBackground.css";

const ChatBackground = ({ className = "" }) => {
  return (
    <div className={`bgblack-1-wrapper ${className}`}>
      <img className="bgblack-1-icon" alt="" src="/bgblack-1@2x.png" />
    </div>
  );
};

ChatBackground.propTypes = {
  className: PropTypes.string,
};

export default ChatBackground;
