import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./OrgSuccess.css";

const OrgSuccess = () => {
  const navigate = useNavigate();

  const onFrameLinkClick = useCallback(() => {
    navigate("/pricing");
  }, [navigate]);

  const onGroupLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onSubmitClick = useCallback(() => {
    navigate("/org-success");
  }, [navigate]);

  return (
    <div className="org-success">
      <img className="org-success-child" alt="" src="/rectangle-6702.svg" />
      <div className="nav-bar2">
        <Link
          className="pricing-wrapper"
          to="/pricing"
          onClick={onFrameLinkClick}
        >
          <div className="pricing2">Pricing</div>
        </Link>
      </div>
      <Link className="synclyfy-v1-1-group" to="/" onClick={onGroupLinkClick}>
        <img className="synclyfy-v1-1-icon1" alt="" src="/synclyfy-v1-1.svg" />
        <img className="synclyfyv2-1-icon1" alt="" src="/synclyfyv2-1@2x.png" />
      </Link>
      <div className="success-youve-secured">
        Success! You’ve secured your spot. Get ready to experience Synclyfy
        soon!
      </div>
      <img className="check-circle-icon" alt="" src="/check-circle.svg" />
      <div className="wed-love-your">
        We’d love your feedback! Share your thoughts and help us make Synclyfy
        even better.
      </div>
      <div className="form">
        <div className="form-child" />
        <div className="what-features-of">
          What features of Synclyfy are most valuable for organizing your
          events?
        </div>
        <input className="form-item" type="text" />
        <input className="form-inner" type="text" />
        <input className="rectangle-input" type="text" />
        <input className="form-child1" type="text" />
        <div className="how-do-you">
          How do you feel about Synclyfy's pricing plans? Are there any features
          or tiers you think should be adjusted or added?
        </div>
        <div className="how-does-synclyfy">
          How does Synclyfy compare to other event management tools you’ve used?
        </div>
        <div className="are-there-any">
          Are there any additional features you’d like to suggest?
        </div>
      </div>
      <a className="submit1" onClick={onSubmitClick}>
        <div className="submit-child" />
        <div className="submit2">Submit</div>
      </a>
    </div>
  );
};

export default OrgSuccess;
