import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import "./ArticlesLearnMore.css";

const ArticlesLearnMore = () => {
  const navigate = useNavigate();

  const onGroupLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onFrameLinkClick = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  return (
    <div className="articles-learn-more">
      <div className="articles-learn-more-child" />
      <b className="unlock-exclusive-access">
        Unlock exclusive access to insightful articles once you join our waiting
        list. Don’t miss out—secure your spot today!
      </b>
      <b className="coming-soon">Coming soon!</b>
      <div className="nav-bar5">
        <NavBar />
        <Link
          className="synclyfy-v1-1-parent1"
          to="/"
          onClick={onGroupLinkClick}
        >
          <img
            className="synclyfy-v1-1-icon4"
            alt=""
            src="/synclyfy-v1-1.svg"
          />
          <img
            className="synclyfyv2-1-icon4"
            alt=""
            src="/synclyfyv2-11@2x.png"
          />
        </Link>
      </div>
      <Link className="join-wrapper" to="/signup" onClick={onFrameLinkClick}>
        <div className="join">
          <div className="join-child" />
          <div className="join-now">Join Now</div>
        </div>
      </Link>
    </div>
  );
};

export default ArticlesLearnMore;
