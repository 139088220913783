import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./Articles.css";

const Articles = ({ className = "" }) => {
  const navigate = useNavigate();

  const onFrameLinkClick = useCallback(() => {
    navigate("/articles-learn-more");
  }, [navigate]);

  return (
    <div className={`articles1 ${className}`}>
      <img className="art-swipe-icon5" alt="" src="/art-swipe.svg" />
      <div className="networking-is-more-than-just-e-group">
        <div className="networking-is-more1">
          Networking is more than just exchanging business cards—it's about
          building relationships that can help you grow both personally and
          professionally. Whether you're attending a conference, workshop, or
          social event, the connections you make can open doors to new
          opportunities. In this guide, we’ll explore strategies for effective
          networking, from preparation to follow-up, so you can make the most of
          your time at any event.
        </div>
        <div className="mr-chat-gpt-wrapper3">
          <div className="mr-chat-gpt5">-Mr. Chat GPT</div>
        </div>
      </div>
      <div className="start-your-networking-journey-container">
        <div className="start-your-networking1">
          Start Your Networking Journey
        </div>
      </div>
      <div className="articles-child" />
      <div className="articles-inner">
        <div className="frame-child33" />
      </div>
      <Link
        className="learn-more-wrapper4"
        to="/articles-learn-more"
        onClick={onFrameLinkClick}
      >
        <div className="learn-more6">Learn More</div>
      </Link>
      <div className="arrow-head-15">
        <img className="vector-icon6" alt="" src="/vector10.svg" />
      </div>
      <img className="arrow-head-25" alt="" src="/arrow-head-2@2x.png" />
    </div>
  );
};

Articles.propTypes = {
  className: PropTypes.string,
};

export default Articles;
