import React from 'react';
import PropTypes from "prop-types";
import "./Form.css";

const Form = ({ className = "" }) => {
  return (
    <div className={`form2 ${className}`}>
      <div className="form-child2" />
      <div className="what-features-of1">
        What features of Synclyfy are you most excited about?
      </div>
      <input className="form-child3" type="text" />
      <input className="form-child4" type="text" />
      <input className="form-child5" type="text" />
      <input className="form-child6" type="text" />
      <div className="how-likely-are">
        How likely are you to recommend Synclyfy to others?
      </div>
      <div className="is-there-anything">
        Is there anything specific you’re hoping to see in the platform?
      </div>
      <div className="are-there-any1">
        Are there any additional features you’d like to suggest?
      </div>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
};

export default Form;
