import React from 'react';
import { useMemo, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./JoinNow.css";

const JoinNow = ({ className = "", propFlex, propFlex1 }) => {
  const frameAStyle = useMemo(() => {
    return {
      flex: propFlex,
    };
  }, [propFlex]);

  const joinStyle = useMemo(() => {
    return {
      flex: propFlex1,
    };
  }, [propFlex1]);

  const navigate = useNavigate();

  const onFrameLinkClick = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  return (
    <Link
      className={`join-wrapper1 ${className}`}
      to="/signup"
      onClick={onFrameLinkClick}
      style={frameAStyle}
    >
      <div className="join5" style={joinStyle}>
        <div className="join-child1" />
        <div className="join-now1">Join Now</div>
      </div>
    </Link>
  );
};

JoinNow.propTypes = {
  className: PropTypes.string,

  /** Style props */
  propFlex: PropTypes.any,
  propFlex1: PropTypes.any,
};

export default JoinNow;
