import React from 'react';
import PropTypes from "prop-types";
import "./VirtualCommunity.css";

const VirtualCommunity = ({ className = "" }) => {
  return (
    <div className={`virtual-community1 ${className}`} id="uni-c">
      <div className="uni-commun1">
        <img className="shapes-1-icon6" alt="" src="/shapes-1@2x.png" />
        <img className="uni-commun-child6" alt="" src="/frame-450@2x.png" />
        <div className="uni-commun-child7" />
        <div className="uni-commun-child8" />
        <div className="uni-commun-child9" />
        <div className="uni-commun-child10" />
        <div className="uni-commun-child11" />
        <div className="uni-commun-child12" />
        <div className="uni-commun-child13" />
        <img
          className="unsplashieebwgy-6la-icon46"
          alt=""
          src="/unsplashieebwgy-6la@2x.png"
        />
        <img
          className="unsplashieebwgy-6la-icon47"
          alt=""
          src="/unsplashieebwgy-6la4@2x.png"
        />
        <img
          className="unsplashieebwgy-6la-icon48"
          alt=""
          src="/unsplashieebwgy-6la5@2x.png"
        />
        <img
          className="unsplashieebwgy-6la-icon49"
          alt=""
          src="/unsplashieebwgy-6la1@2x.png"
        />
        <img
          className="unsplashieebwgy-6la-icon50"
          alt=""
          src="/unsplashieebwgy-6la6@2x.png"
        />
        <img
          className="unsplashieebwgy-6la-icon51"
          alt=""
          src="/unsplashieebwgy-6la2@2x.png"
        />
        <img
          className="unsplashieebwgy-6la-icon52"
          alt=""
          src="/unsplashieebwgy-6la3@2x.png"
        />
        <div className="hey-any-recommendations1">
          Hey, any recommendations for C++ open-source projects?💻
        </div>
        <div className="im-into-front-end1">
          I’m into front-end dev. Any project suggestions?🤔
        </div>
        <div className="check-out-bootstrap1">
          Check out Bootstrap, Ali. It’s widely used in front-end dev.
        </div>
        <div className="count-me-in1">Count me in as a mentor 👩‍🏫</div>
        <div className="im-in-for1">I’m in for AI!🤖</div>
        <div className="by-the-way-container1">
          <p className="by-the-way1">{`By the way, our Programming Society hackathon `}</p>
          <p className="by-the-way1">is next month! Who’s in?</p>
        </div>
        <div className="try-contributing-to1">
          Try contributing to Mozilla Firefox.
        </div>
        <div className="chat-footer6">
          <div className="chat-footer-child4" />
          <i className="message7">Message</i>
          <img className="plus-thin-16" alt="" src="/plus-thin-1.svg" />
          <img className="mic-1-icon6" alt="" src="/mic-1.svg" />
          <img className="image-1-icon6" alt="" src="/image-1.svg" />
        </div>
        <img className="nav-bar-icon1" alt="" src="/nav-bar.svg" />
        <div className="satck1">
          <div className="satck-item" />
          <button className="techstack1" id="ts-c">
            <a className="techstack-item" />
            <div className="tech-stack-community3">{`Tech Stack Community `}</div>
          </button>
          <button className="rectangle-parent21" id="o-c">
            <div className="frame-child36" />
            <div className="organizer-community5">{`Organizer Community `}</div>
          </button>
          <button className="satck-inner1" id="u-c">
            <div className="frame-child37" />
          </button>
          <div className="university-community3">University Community</div>
          <div className="connect-with-students1">
            Connect with students, faculty, and alumni through events and lively
            chats!
          </div>
        </div>
      </div>
      <div className="attnorg-ui-btn10">
        <button className="attnorg-ui-btn-inner8">
          <div className="frame-child38" />
        </button>
        <div className="attendee-ui7">Attendee UI</div>
        <div className="rectangle-parent22">
          <div className="frame-child39" />
          <div className="organizer-ui7">Organizer UI</div>
        </div>
      </div>
    </div>
  );
};

VirtualCommunity.propTypes = {
  className: PropTypes.string,
};

export default VirtualCommunity;
