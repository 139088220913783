import React from 'react';
import PropTypes from "prop-types";
import "./FeedUi1.css";

const FeedUi1 = ({ className = "" }) => {
  return (
    <div className={`feed-ui1 ${className}`}>
      <img className="attendee-feed-icon" alt="" src="/attendee-feed@2x.png" />
      <div className="attnorg-ui-btn9">
        <button className="attnorg-ui-btn-inner7" id="afeed">
          <div className="frame-child34" />
        </button>
        <button className="rectangle-parent20" id="afeed">
          <div className="frame-child35" />
          <div className="organizer-feed2">Organizer Feed</div>
        </button>
        <div className="attendee-feed2">Attendee Feed</div>
      </div>
    </div>
  );
};

FeedUi1.propTypes = {
  className: PropTypes.string,
};

export default FeedUi1;
