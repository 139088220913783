import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Email from "../components/Email";
import "./Attendee.css";

const Attendee = () => {
  const navigate = useNavigate();

  const onNavBarContainerClick = useCallback(() => {
    navigate("/pricing");
  }, [navigate]);

  const onGroupLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onFrameLinkClick = useCallback(() => {
    navigate("/success");
  }, [navigate]);

  return (
    <div className="attendee1">
      <img className="attendee-child" alt="" src="/rectangle-6702.svg" />
      <div className="nav-bar7" onClick={onNavBarContainerClick}>
        <Link className="pricing-wrapper1" to="/pricing">
          <div className="pricing6">Pricing</div>
        </Link>
      </div>
      <Link className="synclyfy-v1-1-parent3" to="/" onClick={onGroupLinkClick}>
        <img className="synclyfy-v1-1-icon6" alt="" src="/synclyfy-v1-1.svg" />
        <img className="synclyfyv2-1-icon6" alt="" src="/synclyfyv2-1@2x.png" />
      </Link>
      <div className="beta-access-is1">
        Beta access is limited—join Synclyfy now!
      </div>
      <div className="rectangle-parent2">
        <div className="group-child47" />
        <div className="name-group">
          <input
            className="name4"
            id="full-name"
            placeholder="Full name"
            type="text"
          />
          <div className="email3">
            <Email />
            <Link
              className="join-frame"
              to="/success"
              onClick={onFrameLinkClick}
            >
              <div className="join3">
                <div className="join-inner" />
                <input
                  className="join4"
                  id="email"
                  value="Join"
                  placeholder="Email"
                  type="email"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendee;
