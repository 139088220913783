import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChatBackground from "../components/ChatBackground";
import GroupComponent1 from "../components/GroupComponent1";
import Articles from "../components/Articles";
import Testimonials from "../components/Testimonials";
import FeedUi1 from "../components/FeedUi1";
import Fromsanapay from "../components/Fromsanapay";
import VirtualCommunity from "../components/VirtualCommunity";
import JoinNow from "../components/JoinNow";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();

  const onLinkediniconClick = useCallback(() => {
    window.open("www.linkedin.com/company/synclyfy");
  }, []);

  const onInstaiconClick = useCallback(() => {
    window.open("https://www.instagram.com/synclyfy.official/");
  }, []);

  const onLayerClick = useCallback(() => {
    window.location.href = "mailto:synclyfy@gmail.com";
  }, []);

  const onActionsContainerClick = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  const onFrameLinkClick = useCallback(() => {
    navigate("/articles-learn-more");
  }, [navigate]);

  const onPricingTextClick = useCallback(() => {
    navigate("/pricing");
  }, [navigate]);

  return (
    <div className="home">
      <div className="home-wrapper">
        <div className="home1">
          <div className="footer">
            <img
              className="linkedinicon-1"
              alt=""
              src="/linkedinicon-1.svg"
              onClick={onLinkediniconClick}
            />
            <img
              className="instaicon-1"
              alt=""
              src="/instaicon-1.svg"
              onClick={onInstaiconClick}
            />
            <div className="trademark">
              <div className="synclyfy-all-rights">
                © 2024 Synclyfy. All rights reserved.
              </div>
            </div>
            <img className="line-icon" alt="" src="/line.svg" />
            <img
              className="layer-2-icon"
              alt=""
              src="/layer-2.svg"
              onClick={onLayerClick}
            />
            <img className="footer-child" alt="" src="/group-20217@2x.png" />
          </div>
          <div className="before-footer">
            <div className="before-footer-inner">
              <div className="frame-child" />
            </div>
            <div className="discover-the-most-exciting-tec-wrapper">
              <div className="discover-the-most">
                Discover The Most Exciting Tech Events Near You
              </div>
            </div>
            <div className="loginsignup-3">
              <div className="actions" onClick={onActionsContainerClick}>
                <div className="stylesecondary-smallfalse">
                  <div className="button">Get Started-It’s Free</div>
                </div>
              </div>
            </div>
          </div>
          <div className="founders">
            <div className="the-founders">The Founders</div>
            <img className="team-bg-tile" alt="" src="/team-bg-tile@2x.png" />
            <div className="urooba-tile">
              <div className="urooba-info">
                <div className="urooba-info-child" />
                <div className="urooba-gohar">Urooba Gohar</div>
                <div className="co-founder">Co-Founder</div>
                <img className="urooba-icons" alt="" src="/urooba-icons.svg" />
              </div>
              <img
                className="urooba-tile-icon"
                alt=""
                src="/urooba-tile@2x.png"
              />
            </div>
            <div className="aimal-tile">
              <div className="aimal-info-rect">
                <div className="aimal-info-rect-child" />
                <div className="aimal-khan">Aimal Khan</div>
                <div className="founder-ceo">{`Founder & CEO`}</div>
                <img className="aimal-icons" alt="" src="/aimal-icons.svg" />
              </div>
              <img
                className="aimal-tile-icon"
                alt=""
                src="/aimal-tile@2x.png"
              />
            </div>
          </div>
          <div className="chabot">
            <div className="meet-catbot-your-purr-fect-te-wrapper">
              <div className="meet-catbot-your">
                Meet Catbot, Your Purr-fect Tech Companion!
              </div>
            </div>
            <div className="introducing-catbot-hebbonn-ou-wrapper">
              <div className="introducing-catbot-hebbonn-container">
                <span className="introducing-catbot">{`Introducing Catbot `}</span>
                <b>Hebbonn</b>
                <span className="introducing-catbot">
                  , our adorable and savvy chatbot with a playful cat persona!
                  Whether you're looking for event details, networking tips, or
                  just some friendly advice, Hebbonn is here to help 24/7. With
                  its cute charm and tech-savvy smarts, Hebbonn makes navigating
                  Synclyfy a breeze. Get ready for a paws-itively delightful
                  experience with your new feline friend! But be careful she’s
                  got sharp nails ⚠ ️
                </span>
              </div>
            </div>
            <img className="cat-2-icon" alt="" src="/cat-2@2x.png" />
            <ChatBackground />
            <img className="chat-ui-icon" alt="" src="/chat-ui@2x.png" />
          </div>
          <div className="leader-board">
            <div className="the-synclyfy-leaderboard-container">
              <span className="introducing-catbot">{`The Synclyfy Leaderboard `}</span>
              <span className="ranks-communities-based">
                ranks communities based on event attendance
              </span>
              <span className="introducing-catbot">{`, driving organizers to deliver outstanding experiences. `}</span>
              <span className="ranks-communities-based">
                This feature highlights the most active and influential
                communities
              </span>
              <span className="introducing-catbot">
                , motivating both organizers and attendees to engage and connect
                with top-performing networks.
              </span>
            </div>
            <div className="leaderboard-inspiring-excelle">
              Leaderboard: Inspiring Excellence in Tech Communities
            </div>
            <img
              className="leader-board-icon"
              alt=""
              src="/leader-board@2x.png"
            />
          </div>
          <div className="virtaul-community-attendee">
            <div className="join-the-virtual-communities-wrapper">
              <div className="join-the-virtual">
                Join the Virtual Communities!
              </div>
            </div>
          </div>
          <div className="uni-hackathons">
            <div className="challenge-accepted-inter-univ-wrapper">
              <div className="challenge-accepted-inter-univ">{`Challenge Accepted! Inter-University Hackathons & Competitions`}</div>
            </div>
            <img
              className="uni-hackathons-child"
              alt=""
              src="/community-illustration@2x.png"
            />
            <img
              className="uni-hackathons-item"
              alt=""
              src="/community-illustration1@2x.png"
            />
            <div className="ready-to-show-off-your-skills-wrapper">
              <div className="ready-to-show-container">
                <span className="introducing-catbot">{`Ready to show off your skills and compete with the best? `}</span>
                <span className="ranks-communities-based">
                  Synclyfy now features inter-university hackathons and
                  competitions!
                </span>
                <span className="introducing-catbot">
                  {" "}
                  Connect with peers, innovate together, and see who comes out
                  on top. Get ready for some friendly rivalry and epic tech
                  battles!
                </span>
              </div>
            </div>
          </div>
          <div className="chat">
            <div className="chat1">
              <div className="frame-parent">
                <div className="group-child" />
                <div className="engage-with-fellow">
                  Engage with fellow attendees, share insights, and collaborate
                  on events in real-time with Synclyfy's integrated chat
                  feature.
                </div>
                <div className="connect-collaborate">{`Connect & Collaborate: Synclyfy's Chat Feature`}</div>
              </div>
              <img className="chats-2-icon" alt="" src="/chats-2@2x.png" />
            </div>
          </div>
          <div className="fellow-notiff-and-social-media">
            <div className="netwrksocail-media">
              <div className="curious-whos-joining">
                Curious who’s Joining you? And want To Connect?
              </div>
              <div className="get-notified-about-container">
                <span className="introducing-catbot">{`Get `}</span>
                <span className="ranks-communities-based">
                  notified about fellow tech enthusiasts from your city,
                  university, or tech stack!
                </span>
                <span className="introducing-catbot">
                  {" "}
                  Connect instantly with our in-app chat feature. Networking has
                  never been this easy and exciting!
                </span>
              </div>
              <img
                className="netwrksocail-media-child"
                alt=""
                src="/connect-image-container@2x.png"
              />
              <div className="shout-out-your">Shout Out Your Event Wins!</div>
            </div>
            <div className="went-to-an-container">
              <span className="introducing-catbot">{`Went to an awesome event but kept it to yourself? Let’s fix that! Share your tech adventures with `}</span>
              <b>our cool social media templates</b>
              <span className="introducing-catbot">
                {" "}
                and let everyone know you’re in the game!
              </span>
            </div>
            <img
              className="fellow-notiff-and-social-media-child"
              alt=""
              src="/share-prompt@2x.png"
            />
          </div>
          <div className="notification">
            <div className="calendernotif">
              <div className="if-missed-never">
                If Missed, NEVER miss Again!!
              </div>
              <div className="stay-in-the-container">
                <span className="introducing-catbot">
                  Stay in the tech loop! Get hilarious push
                </span>
                <span className="ranks-communities-based">
                  {" "}
                  notifications about the coolest tech events near you or
                  wherever you fancy
                </span>
                <span className="introducing-catbot">{`. And guess what? You can now `}</span>
                <span className="ranks-communities-based">
                  sync these events straight to your calendar
                </span>
                <span className="introducing-catbot">
                  , so you’ll never miss a beat!
                </span>
              </div>
            </div>
            <img
              className="notification-icon"
              alt=""
              src="/notification@2x.png"
            />
          </div>
          <div className="event-materials">
            <div className="but-just-forgot-container">
              <p className="but-just-forgot">But Just forgot the Topics?</p>
              <p className="but-just-forgot">We gotchh youu :)</p>
            </div>
            <div className="attended-event">Attended Event!</div>
            <div className="attened-or-miss">
              <img
                className="attended-event-icon"
                alt=""
                src="/attended-event@2x.png"
              />
              <div className="missed-event">Missed Event?</div>
              <div className="but-curious-about-container">
                <p className="but-just-forgot">
                  But Curious About What Was there?
                </p>
                <p className="but-just-forgot">We Gotchh youu :)</p>
              </div>
              <img
                className="arrow-down-right-icon"
                alt=""
                src="/arrow-downright1@2x.png"
              />
            </div>
            <img
              className="arrow-down-right-icon1"
              alt=""
              src="/arrow-downright.svg"
            />
          </div>
          <div className="transport">
            <div className="explore-det-exact">
              <div className="explore-get-exact">
                Explore, get Exact Location plusss Transport!
              </div>
              <div className="with-our-location">
                With our location feature, you can easily find the exact venue
                for the event on the map. We also provide convenient transport
                options for your convenience.
              </div>
            </div>
            <img
              className="explore-img-icon"
              alt=""
              src="/explore-img@2x.png"
            />
          </div>
          <div className="hidden-pre">
            <div className="registrationpayments">
              <img className="bg-1-icon" alt="" src="/bg-1@2x.png" />
              <div className="fed-up-with">
                Fed Up with External Forms and Untrusted Payments?
              </div>
              <div className="weve-got-you-covered-enjoy-s-wrapper">
                <div className="weve-got-you-container">
                  <span className="introducing-catbot">{`We've Got You Covered! Enjoy Seamless `}</span>
                  <b>In-App Registration</b>
                  <span className="introducing-catbot">
                    {" "}
                    and Secure Payments in Seconds.
                  </span>
                </div>
              </div>
              <div className="registeration-form">
                <div className="reg-from-logo">
                  <div className="registeration-form1">Registeration form</div>
                </div>
                <div className="name">
                  <div className="name-child" />
                  <div className="address">Name</div>
                </div>
                <div className="email">
                  <div className="name-child" />
                  <div className="address">Email</div>
                </div>
                <div className="phone">
                  <div className="name-child" />
                  <div className="address">Phone</div>
                </div>
                <div className="adress">
                  <div className="name-child" />
                  <div className="address">Address</div>
                </div>
                <div className="registr-submit">
                  <div className="registr-submit-child" />
                  <div className="submit">Submit</div>
                </div>
                <img
                  className="synclyfybg-1-icon"
                  alt=""
                  src="/synclyfybg-1.svg"
                />
              </div>
              <div className="payment-form">
                <div className="payments-bg-rectangle" />
                <div className="secure-payments">
                  <div className="registeration-form1">Secure Payments</div>
                </div>
                <div className="payment-gateway-logo-vec-16772">
                  <img className="layer-1-icon" alt="" src="/layer-1.svg" />
                  <div className="or">OR</div>
                </div>
                <div className="easypaisa">
                  <div className="easypaisa-child" />
                  <div className="pay-with">{`Pay With `}</div>
                  <img
                    className="b39f879357-easypaisa-1-icon"
                    alt=""
                    src="/665b39f879357easypaisa-1@2x.png"
                  />
                </div>
                <div className="jazz-cah">
                  <div className="jazz-cah-child" />
                  <div className="pay-with1">{`Pay With `}</div>
                  <img
                    className="jazzcash-icon"
                    alt=""
                    src="/jazzcash@2x.png"
                  />
                </div>
                <div className="pay-next">
                  <div className="registr-submit-child" />
                  <div className="next">Next</div>
                </div>
                <img
                  className="synclyfybg-1-icon1"
                  alt=""
                  src="/synclyfybg-1.svg"
                />
              </div>
            </div>
            <div className="the-profiles">
              <img className="vector-icon" alt="" src="/vector8.svg" />
              <div className="community-pfp">
                <div className="community-pfp-child" />
                <div className="with-synclyfys-speaker">
                  With Synclyfy's Speaker Profiles, attendees can follow their
                  favorite speakers and get notified about their sessions, while
                  organizers can easily find and book the perfect speakers for
                  their events.
                </div>
                <div className="speaker-profile">Speaker Profile</div>
              </div>
              <div className="user-profile">
                <div className="community-pfp-child">
                  <div className="user-profile1">User Profile</div>
                  <div className="create-a-personalized">
                    Create a personalized user profile to showcase your
                    interests, education, and skills, while tracking your event
                    attendance, connecting with fellow attendees, and sharing
                    your event experiences.
                  </div>
                </div>
                <img className="user-pf-logo" alt="" src="/user-pf-logo.svg" />
                <div className="personalpfp-1" />
              </div>
              <img className="user-pf-logo1" alt="" src="/user-pf-logo1.svg" />
              <div className="loginsignup-2">
                <div className="actions1">
                  <div className="stylesecondary-smallfalse1">
                    <div className="button1">Log In</div>
                  </div>
                </div>
                <div className="actions2">
                  <div className="stylesecondary-smallfalse2">
                    <div className="button2">Sign Up</div>
                  </div>
                </div>
              </div>
              <div className="the-profiles-wrapper">
                <div className="the-profiles1">
                  <div className="the-profiles-child" />
                  <div className="the-profiles2">The Profiles</div>
                </div>
              </div>
            </div>
            <div className="events-feed">
              <div className="events-feed-child" />
              <img
                className="events-feed-item"
                alt=""
                src="/rectangle-12@2x.png"
              />
              <img
                className="events-feed-inner"
                alt=""
                src="/rectangle-13@2x.png"
              />
              <img
                className="rectangle-icon"
                alt=""
                src="/rectangle-14@2x.png"
              />
              <div className="personilized">
                <div className="attended-event">Personalized Events Feed</div>
                <div className="discover-events-tailored-container">
                  <p className="but-just-forgot">{`Discover Events Tailored To `}</p>
                  <p className="but-just-forgot">
                    Your Interests And Preferences
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="check-ins">
            <div className="streamline-check-ins-with">
              Streamline Check-Ins with QR Codes
            </div>
            <div className="say-goodbye-to-container">
              <span className="introducing-catbot">{`Say `}</span>
              <span>{`goodbye to manual check-ins! `}</span>
              <span className="introducing-catbot">{`Our QR check-in feature simplifies the process, providing `}</span>
              <span>
                real-time attendance counts and detailed participant lists for
                organizers
              </span>
              <span className="introducing-catbot">
                . Effortless tracking and seamless entry—just scan and go!
              </span>
            </div>
            <img
              className="d-character-emerging-from-smar-icon"
              alt=""
              src="/3dcharacteremergingfromsmartphone-1@2x.png"
            />
          </div>
          <div className="virtaul-events">
            <div className="explore-connect-and-grow-wit-wrapper">
              <div className="explore-connect-and">
                Explore, connect, and grow with Synclyfy's dynamic virtual
                events. Whether you're looking to network, learn, or showcase
                your skills, our platform offers the perfect space to engage
                with industry leaders and peers. Dive in and make the most of
                every opportunity!
              </div>
            </div>
            <div className="virtaul-events-child" />
            <img
              className="virtual-events-icon"
              alt=""
              src="/virtual-events@2x.png"
            />
            <div className="unlock-the-power">
              Unlock the Power of Virtual Events
            </div>
          </div>
          <div className="registration">
            <div className="effortless-registration-payme">
              Effortless Registration, Payments, and Real-Time Analytics
            </div>
            <div className="streamline-event-management-container">
              <span className="introducing-catbot">
                Streamline event management with easy
              </span>
              <span>
                {" "}
                in-app registration and payments. Track attendee data and gain
                real-time insights
              </span>
              <span className="introducing-catbot">
                {" "}
                for a smooth, efficient experience.
              </span>
            </div>
          </div>
          <div className="ads">
            <div className="promote-and-sponsor-your-event-parent">
              <div className="attended-event">
                Promote and Sponsor Your Events
              </div>
              <div className="boost-your-events">
                Boost your event's visibility with Synclyfy's promotion and
                sponsorship tools. Reach a wider audience and attract sponsors
                to maximize your event's success.
              </div>
            </div>
            <img
              className="sponsor-event-icon"
              alt=""
              src="/sponsor-event@2x.png"
            />
            <img className="search-2-icon" alt="" src="/search-2.svg" />
          </div>
          <img
            className="home-child"
            alt=""
            src="/hackathon-image@2x@2x@2x@2x.svg"
          />
          <div className="description">
            <div className="synclyfy-curates-events-container">
              <span className="introducing-catbot">{`Synclyfy curates events tailored to your interests, `}</span>
              <span>connects you with fellow attendees</span>
              <span className="introducing-catbot">
                , and lets you showcase your profile.
              </span>
              <span>
                You can also embed your social media profiles right in your page
              </span>
              <span className="introducing-catbot">
                {" "}
                with our cool widgets. Follow your favorite speakers and stay
                updated on their sessions
              </span>
            </div>
            <div className="your-personalized-feed">Your Personalized Feed</div>
          </div>
          <div className="discover">
            <div className="registeration-form1">Discover</div>
            <div className="explore-exciting-features-container">
              <p className="but-just-forgot">Explore Exciting Features</p>
              <p className="but-just-forgot">Of Synclyfy</p>
            </div>
            <div className="synclyfy-offers-a">
              Synclyfy offers a range of powerful features designed to enhance
              your tech event experience. From creating personalized profiles to
              accessing event materials, our platform has everything you need to
              stay connected and informed.
            </div>
          </div>
          <div className="after-hero">
            <div className="explore-tech-events">
              Explore Tech Events and Networking Hubs
            </div>
            <div className="discover-conferences-workshop">
              Discover conferences, workshops, and networking opportunities
              across the globe, all in one place
            </div>
          </div>
          <img className="home-item" alt="" src="/hero-image.svg" />
          <div className="loginsignup-1">
            <div className="actions1">
              <div className="stylesecondary-smallfalse1">
                <div className="button1">Log In</div>
              </div>
            </div>
            <div className="actions2">
              <div className="stylesecondary-smallfalse2">
                <div className="button2">Sign Up</div>
              </div>
            </div>
          </div>
          <Link
            className="actions-wrapper"
            to="/signup"
            onClick={onActionsContainerClick}
          >
            <div className="actions1">
              <div className="stylesecondary-smallfalse5">
                <div className="button">Get Started- it’s free</div>
              </div>
            </div>
          </Link>
          <div className="hero-para">
            <div className="hero-para-child" />
            <div className="unite-with-tech-container">
              <span>{`Unite with tech enthusiasts worldwide on `}</span>
              <b>Synclyfy</b>
              <span>
                . Discover personalized events, connect with vibrant
                communities, and network seamlessly—all in one powerful
                platform. Stay ahead in the tech world, right here
              </span>
            </div>
          </div>
          <div className="netwrking">
            <div className="master-the-art-of-networking-wrapper">
              <div className="meet-catbot-your">
                Master the Art of Networking
              </div>
            </div>
            <div className="group-parent">
              <GroupComponent1 />
              <Articles />
            </div>
            <div className="used-our-notifications-and-soc-wrapper">
              <div className="used-our-notifications">
                Used our notifications and social media features but still
                finding networking tricky? Check out our articles for expert
                tips on how to build your tech network like a pro!
              </div>
            </div>
            <div className="netwrking-inner">
              <div className="frame-item" />
            </div>
            <Link
              className="learn-more-wrapper"
              to="/articles-learn-more"
              onClick={onFrameLinkClick}
            >
              <div className="synclyfy-all-rights">Learn More</div>
            </Link>
          </div>
          <div className="testimonials">
            <img
              className="testimonials-child"
              alt=""
              src="/empty-container.svg"
            />
            <div className="vector-parent">
              <img className="frame-inner" alt="" src="/polygon-2.svg" />
              <Testimonials />
            </div>
          </div>
          <div className="design">
            <div className="design-marketplace">Design Marketplace</div>
            <div className="discover-a-wide">
              Discover a wide range of pre-made templates in Synclyfy's Design
              Marketplace. Easily find and customize designs for your events,
              saving time and enhancing your event's visual appeal
            </div>
            <img
              className="design-marketplace-icon"
              alt=""
              src="/design-marketplace@2x.png"
            />
            <img className="search-1-icon" alt="" src="/search-1.svg" />
          </div>
          <FeedUi1 />
          <Fromsanapay />
          <VirtualCommunity />
          <div className="nav-bar">
            <div className="nav-bar1">
              <JoinNow />
              <div className="pricing" onClick={onPricingTextClick}>
                Pricing
              </div>
            </div>
            <div className="logo">
              <img className="logo-child" alt="" src="/group-20227.svg" />
              <img className="logo-item" alt="" src="/group-20226@2x.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
