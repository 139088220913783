import React from 'react';
import PropTypes from "prop-types";
import "./Fromsanapay.css";

const Fromsanapay = ({ className = "" }) => {
  return (
    <section className={`fromsanapay ${className}`} id="form">
      <div className="forms">
        <div className="forms">
          <div className="registration-forms-child" />
          <div className="forms-rect-wrapper">
            <div className="forms-rect" />
          </div>
          <div className="browse-pre-made-templates">
            BROWSE PRE-MADE TEMPLATES
          </div>
          <img className="arrow-head-16" alt="" src="/arrow-head-1.svg" />
          <div className="or1">OR</div>
          <div className="forms1">
            <div className="registration1">
              <div className="registration-child" />
              <div className="registration-form">
                <p className="create-from">Registration</p>
                <p className="scratch"> form</p>
              </div>
              <img className="vector-icon7" alt="" src="/vector2.svg" />
              <img className="vector-icon8" alt="" src="/vector3.svg" />
              <img className="vector-icon9" alt="" src="/vector5.svg" />
              <img className="vector-icon10" alt="" src="/vector4.svg" />
            </div>
            <div className="ai">
              <div className="ai-child" />
              <div className="create-with-ai">Create With AI</div>
              <img className="vector-icon11" alt="" src="/vector6.svg" />
              <img className="vector-icon12" alt="" src="/vector7.svg" />
            </div>
            <div className="create">
              <div className="create-child" />
              <div className="create-from-scratch-container">
                <p className="create-from">Create from</p>
                <p className="scratch"> scratch</p>
              </div>
              <img className="vector-icon13" alt="" src="/vector1.svg" />
            </div>
          </div>
          <div className="forms2">Forms</div>
          <div className="side-nav">
            <div className="small-rect" />
            <button className="dollor-1" id="pay">
              <img className="vector-icon14" alt="" src="/vector.svg" />
              <img className="vector-icon15" alt="" src="/vector9.svg" />
            </button>
            <button className="analytics2-1" id="ana">
              <img className="vector-icon16" alt="" src="/vector11.svg" />
              <img className="vector-icon17" alt="" src="/vector12.svg" />
              <img className="vector-icon18" alt="" src="/vector13.svg" />
              <img className="vector-icon19" alt="" src="/vector14.svg" />
              <img className="vector-icon20" alt="" src="/vector15.svg" />
              <img className="vector-icon21" alt="" src="/vector16.svg" />
            </button>
            <button className="plus-2-wrapper" id="form">
              <div className="plus-2">
                <img className="vector-icon22" alt="" src="/vector.svg" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

Fromsanapay.propTypes = {
  className: PropTypes.string,
};

export default Fromsanapay;
