import React from 'react';
import PropTypes from "prop-types";
import "./Email.css";

const Email = ({ className = "", onButtonContainerClick }) => {
  return (
    <div className={`email4 ${className}`}>
      <div className="email-item" />
      <i className="email5">Email</i>
    </div>
  );
};

Email.propTypes = {
  className: PropTypes.string,

  /** Action props */
  onButtonContainerClick: PropTypes.func,
};

export default Email;
