import React from 'react';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./OrgSuccess1.css";

const OrgSuccess1 = () => {
  const navigate = useNavigate();

  const onFrameContainerClick = useCallback(() => {
    navigate("/pricing");
  }, [navigate]);

  const onGroupImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="org-success1">
      <img className="org-success-item" alt="" src="/rectangle-6702.svg" />
      <div className="nav-bar8">
        <div className="frame-div" onClick={onFrameContainerClick}>
          <div className="pricing7">Pricing</div>
        </div>
      </div>
      <img
        className="org-success-inner"
        alt=""
        src="/group-202171@2x.png"
        onClick={onGroupImageClick}
      />
      <div className="thank-you-for-your-feedback-y-parent">
        <div className="thank-you-for">
          Thank you for your feedback! Your insights are invaluable in helping
          us improve Synclyfy.
        </div>
        <img className="check-circle-icon2" alt="" src="/check-circle.svg" />
      </div>
    </div>
  );
};

export default OrgSuccess1;
