import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "../components/Form";
import "./Success.css";

const Success = () => {
  const navigate = useNavigate();

  const onFrameLinkClick = useCallback(() => {
    navigate("/pricing");
  }, [navigate]);

  const onGroupLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onSubmitClick = useCallback(() => {
    navigate("/org-success");
  }, [navigate]);

  return (
    <div className="success">
      <img className="success-child" alt="" src="/rectangle-6702.svg" />
      <div className="nav-bar4">
        <Link
          className="pricing-frame"
          to="/pricing"
          onClick={onFrameLinkClick}
        >
          <div className="pricing4">Pricing</div>
        </Link>
      </div>
      <Link className="group-a" to="/" onClick={onGroupLinkClick}>
        <img className="synclyfy-v1-1-icon3" alt="" src="/synclyfy-v1-1.svg" />
        <img className="synclyfyv2-1-icon3" alt="" src="/synclyfyv2-1@2x.png" />
      </Link>
      <div className="success-youve-secured1">
        Success! You’ve secured your spot. Get ready to experience Synclyfy
        soon!
      </div>
      <img className="check-circle-icon1" alt="" src="/check-circle.svg" />
      <div className="wed-love-your1">
        We’d love your feedback! Share your thoughts and help us make Synclyfy
        even better.
      </div>
      <Form />
      <a className="submit3" onClick={onSubmitClick}>
        <div className="submit-item" />
        <div className="submit4">Submit</div>
      </a>
    </div>
  );
};

export default Success;
