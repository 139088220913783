import React from 'react';
import PropTypes from "prop-types";
import "./Testimonials.css";

const Testimonials = ({ className = "" }) => {
  return (
    <div className={`testimonials7 ${className}`}>
      <div className="rectangle-parent19">
        <div className="group-child104" />
        <div className="rectangle-parent19">
          <div className="testimonials-child7" />
          <div className="synclyfy-has-revolutionized1">
            Synclyfy has revolutionized my tech journey! From easy event
            registrations and secure payments to connecting with global
            enthusiasts and sharing on social media, it's all here. The
            inter-university hackathons are a highlight, and Catbot adds a fun
            touch. Love this app!
          </div>
          <div className="founder-xyz4">{`-Founder Xyz `}</div>
        </div>
      </div>
      <img
        className="testimonial-swipe-icon5"
        alt=""
        src="/testimonial-swipe.svg"
      />
      <img className="d-avatars-11" alt="" src="/3d-avatars--1@2x.png" />
    </div>
  );
};

Testimonials.propTypes = {
  className: PropTypes.string,
};

export default Testimonials;
