import React from 'react';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Signup.css";

const Signup = () => {
  const navigate = useNavigate();

  const onFrameLinkClick = useCallback(() => {
    navigate("/pricing");
  }, [navigate]);

  const onFrameLinkClick1 = useCallback(() => {
    navigate("/attendee");
  }, [navigate]);

  const onGroupLinkClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="signup">
      <div className="nav-bar3">
        <Link
          className="pricing-container"
          to="/pricing"
          onClick={onFrameLinkClick}
        >
          <div className="pricing3">Pricing</div>
        </Link>
      </div>
      <div className="frame-group">
        <Link
          className="attendee-btn-wrapper"
          to="/attendee"
          onClick={onFrameLinkClick1}
        >
          <div className="attendee-btn">
            <div className="attendee-btn-child" />
            <div className="attendee">Attendee</div>
          </div>
        </Link>
        <Link
          className="organizer-btn"
          to="/organizer"
          onClick={onFrameLinkClick}
        >
          <div className="attendee-btn-child" />
          <div className="organizer">Organizer</div>
        </Link>
      </div>
      <div className="you-are">You are?</div>
      <Link
        className="synclyfy-v1-1-container"
        to="/"
        onClick={onGroupLinkClick}
      >
        <img className="synclyfy-v1-1-icon2" alt="" src="/synclyfy-v1-1.svg" />
        <img className="synclyfyv2-1-icon2" alt="" src="/synclyfyv2-1@2x.png" />
      </Link>
    </div>
  );
};

export default Signup;
