import React from 'react';
import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import OrgSuccess from "./pages/OrgSuccess";
import Signup from "./pages/Signup";
import Success from "./pages/Success";
import ArticlesLearnMore from "./pages/ArticlesLearnMore";
import Organizer from "./pages/Organizer";
import Attendee from "./pages/Attendee";
import OrgSuccess1 from "./pages/OrgSuccess1";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/pricing":
        title = "";
        metaDescription = "";
        break;
      case "/org-success1":
        title = "";
        metaDescription = "";
        break;
      case "/signup":
        title = "";
        metaDescription = "";
        break;
      case "/success":
        title = "";
        metaDescription = "";
        break;
      case "/articles-learn-more":
        title = "";
        metaDescription = "";
        break;
      case "/organizer":
        title = "";
        metaDescription = "";
        break;
      case "/attendee":
        title = "";
        metaDescription = "";
        break;
      case "/org-success":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/org-success1" element={<OrgSuccess />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/success" element={<Success />} />
      <Route path="/articles-learn-more" element={<ArticlesLearnMore />} />
      <Route path="/organizer" element={<Organizer />} />
      <Route path="/attendee" element={<Attendee />} />
      <Route path="/org-success" element={<OrgSuccess1 />} />
    </Routes>
  );
}
export default App;
