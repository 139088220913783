import React from 'react';
import { useCallback } from "react";
import JoinNow from "./JoinNow";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./NavBar.css";

const NavBar = ({ className = "" }) => {
  const navigate = useNavigate();

  const onPricingTextClick = useCallback(() => {
    navigate("/pricing");
  }, [navigate]);

  return (
    <div className={`nav-bar17 ${className}`}>
      <JoinNow propFlex="unset" propFlex1="unset" />
      <div className="pricing8" onClick={onPricingTextClick}>
        Pricing
      </div>
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
};

export default NavBar;
